@use '@angular/material' as mat;
@use '@angular/material/theming';
@use 'typography' as typography;
@use 'typography-mobile' as typography-mobile;
@use 'palette' as palette;

@use 'projects/uslugi/src/assets/fonts/Inter/inter.css';
@use 'projects/uslugi/src/assets/fonts/Montserrat/montserrat.css';

@use 'button-toggle';
@use 'form-field';
@use 'select';
@use 'tooltips';
@use 'datepicker';
@use 'autocomplete' as ac;
@use 'stepper' as stepper;
@use 'checkbox' as checkbox;

@include stepper.custom-stepper-theme();
@include checkbox.custom-checkbox-theme();
@include ac.custom-autocomplete-theme();

@mixin mat-elevation($zValue, $color: #000, $opacity: 1) {
  @if type-of($zValue) != number or not unitless($zValue) {
    @error '$zValue must be a unitless number';
  }
  @if $zValue < 0 or $zValue > 24 {
    @error '$zValue must be between 0 and 24';
  }

  box-shadow: 0 8px 20px rgba(#d5d9e4, 0.2);
}

@include mat.core(typography.$config);
@media (max-width: 600px) {
  @include mat.core(typography-mobile.$config);
}

$primary: mat.define-palette(palette.$orion);
$accent: mat.define-palette(palette.$orion-accent);
$warn: mat.define-palette(mat.$red-palette);
$theme: mat.define-light-theme($primary, $accent, $warn);

@include mat.all-component-themes($theme);

body {
  background-color: #f7fafe;
  font-family: 'Inter', sans-serif;
}

.wait {
  z-index: 2;
  border-radius: 8px;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);

  mat-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

button {
  font-family: 'Montserrat', sans-serif !important;
  box-shadow: none !important;
  height: 56px;
  border-radius: 8px !important;
}
a,
a:visited {
  cursor: pointer;
  color: #1a85e8;
  text-decoration: underline;
}
