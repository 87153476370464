.panel .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0) !important;
  background: #f7fafe !important;
}

.mat-placeholder-required {
  display: none !important;
}

.mat-form-field-prefix {
  mat-icon {
    margin-right: 10px !important;
  }
}
