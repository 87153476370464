.mat-button-toggle-checked {
  box-shadow: inset 0px 0px 0px 2px #1a85e8;
  background-color: #fff !important;
}

mat-button-toggle button {
  font-family: 'Inter', sans-serif !important;
  font-size: 18px !important;
  line-height: 26px !important;
  font-weight: 400 !important;
}
.mat-button-toggle-standalone,
.mat-button-toggle-group {
  max-height: 60px;
  overflow: hidden;
}

.mat-button-toggle-checked span {
  color: #1a85e8 !important;
}
