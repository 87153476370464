:root {
  --color-50: #fff4e6;
  --color-100: #ffe3c2;
  --color-200: #ffd099;
  --color-300: #ffbd70;
  --color-400: #ffaf51;
  --color-500: #ffa132;
  --color-600: #ff992d;
  --color-700: #ff8f26;
  --color-800: #ff851f;
  --color-900: #ff7413;
  --color-A100: #ffffff;
  --color-A200: #fffcfa;
  --color-A400: #ffdcc7;
  --color-A700: #ffccad;
  --contrast-50: #000000;
  --contrast-100: #000000;
  --contrast-200: #000000;
  --contrast-300: #000000;
  --contrast-400: #000000;
  --contrast-500: #ffffff;
  --contrast-600: #ffffff;
  --contrast-700: #ffffff;
  --contrast-800: #ffffff;
  --contrast-900: #ffffff;
  --contrast-A100: #000000;
  --contrast-A200: #000000;
  --contrast-A400: #000000;
  --contrast-A700: #000000;
}

$orion: (
  50: var(--color-50),
  100: var(--color-100),
  200: var(--color-200),
  300: var(--color-300),
  400: var(--color-400),
  500: var(--color-500),
  600: var(--color-600),
  700: var(--color-700),
  800: var(--color-800),
  900: var(--color-900),
  A100: var(--color-A100),
  A200: var(--color-A200),
  A400: var(--color-A400),
  A700: var(--color-A700),
  contrast: (
    50: var(--contrast-50),
    100: var(--contrast-100),
    200: var(--contrast-200),
    300: var(--contrast-300),
    400: var(--contrast-400),
    500: var(--contrast-500),
    600: var(--contrast-600),
    700: var(--contrast-700),
    800: var(--contrast-800),
    900: var(--contrast-900),
    A100: var(--contrast-A100),
    A200: var(--contrast-A200),
    A400: var(--contrast-A400),
    A700: var(--contrast-A700),
  ),
);

$orion-accent: (
  50: #e4f0fc,
  100: #badaf8,
  200: #8dc2f4,
  300: #5faaef,
  400: #3c97eb,
  500: #1a85e8,
  600: #177de5,
  700: #1372e2,
  800: #0f68de,
  900: #0855d8,
  A100: #ffffff,
  A200: #cfdeff,
  A400: #9cbbff,
  A700: #82aaff,
  contrast: (
    50: var(--contrast-50),
    100: var(--contrast-100),
    200: var(--contrast-200),
    300: var(--contrast-300),
    400: var(--contrast-400),
    500: var(--contrast-500),
    600: var(--contrast-600),
    700: var(--contrast-700),
    800: var(--contrast-800),
    900: var(--contrast-900),
    A100: var(--contrast-A100),
    A200: var(--contrast-A200),
    A400: var(--contrast-A400),
    A700: var(--contrast-A700),
  ),
);
