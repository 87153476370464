@use 'sass:map';
@use '@angular/material' as mat;

$config: mat.define-typography-config(
  $font-family: '"Inter", sans-serif',
  $display-4: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $display-3: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $display-2: mat.define-typography-level(20px, 28px, 400),
  $display-1: mat.define-typography-level(18px, 24px, 700),
  $headline: mat.define-typography-level(56px, 64px, 600),
  // h1
  $title: mat.define-typography-level(32px, 40px, bold),
  // h2
  $subheading-2: mat.define-typography-level(24px, 32px, 600),
  //h3
  $subheading-1: mat.define-typography-level(18px, 22px, 700),
  //h4
  $body-2: mat.define-typography-level(14px, 22px, 400),
  $body-1: mat.define-typography-level(14px, 20px, 400),
  $caption: mat.define-typography-level(12px, 16.8px, 400),
  $button: mat.define-typography-level(18px, 26px, 600),
  $input: mat.define-typography-level(18px, 1.125, 400),
);

.orion-theme {
  .mat-h1,
  .mat-headline,
  h1 {
    margin: 0 0 16px;
  }

  .mat-h2,
  .mat-title,
  h2 {
    margin: 0 0 16px;
  }

  .mat-h3,
  .mat-subheading-2,
  h3 {
    margin: 0 0 16px;
  }

  .mat-h4,
  .mat-subheading-1,
  h4 {
    margin: 0 0 16px;
  }

  .mat-h5,
  h5 {
    margin: 0 0 12px;
  }

  .mat-h6,
  h6 {
    margin: 0 0 12px;
  }

  .mat-body,
  .mat-body-1 {
    p {
      margin: 0 0 12px;
    }
  }

  .mat-display-4 {
    margin: 0 0 56px;
  }

  .mat-display-3 {
    margin: 0 0 64px;
  }

  .mat-display-2 {
    margin: 0 0 8px !important;
  }

  .mat-display-1 {
    margin: 0 0 8px;
  }
}

.body-1 {
  font-size: 24px;
  line-height: 32px;
  font-weight: normal;
}

.body-2 {
  font-size: 18px;
  line-height: 26px;
  font-weight: normal;
}

.body-3 {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
}

.hint {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
}
