html
  margin: 0
  padding: 0

  & *
    box-sizing: border-box


.wrapper
  width: 100%
  max-width: 1180px
  margin: 0 auto
  padding: 0 16px

@media (max-width: 600px)
  .wrapper
    padding: 0 4px

  .mat-horizontal-content-container
    padding: 0 8px 24px 8px !important

  mat-card
    padding-left: 8px !important
    padding-right: 8px !important

html, body
  height: 100%

body
  margin: 0
  font-family: Inter, "Helvetica Neue", sans-serif
  letter-spacing: 0.1px
  color: #2A2B33

  & > *
    -webkit-font-smoothing: antialiased !important
    -moz-osx-font-smoothing: grayscale !important
    text-rendering: optimizeLegibility !important

@media (max-width: 1024px)

.container img
  width: 80%

