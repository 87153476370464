@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-Regular.otf') format('opentype'),
    url('Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/*@font-face {*/
/*  font-family: 'Montserrat';*/
/*  src:*/
/*    url('Montserrat-Black.otf') format('opentype'),*/
/*    url('Montserrat-Black.ttf') format('truetype');*/
/*  font-weight: normal;*/
/*  font-style: normal;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Montserrat';*/
/*  src:*/
/*    url('Montserrat-Bold.otf') format('opentype'),*/
/*    url('Montserrat-Bold.ttf') format('truetype');*/
/*  font-weight: normal;*/
/*  font-style: normal;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Montserrat';*/
/*  src:*/
/*    url('Montserrat-ExtraBold.otf') format('opentype'),*/
/*    url('Montserrat-ExtraBold.ttf') format('truetype');*/
/*  font-weight: normal;*/
/*  font-style: normal;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Montserrat';*/
/*  src:*/
/*    url('Montserrat-ExtraLight.otf') format('opentype'),*/
/*    url('Montserrat-ExtraLight.ttf') format('truetype');*/
/*  font-weight: normal;*/
/*  font-style: normal;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Montserrat';*/
/*  src:*/
/*    url('Montserrat-Light.otf') format('opentype'),*/
/*    url('Montserrat-Light.ttf') format('truetype');*/
/*  font-weight: normal;*/
/*  font-style: normal;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Montserrat';*/
/*  src:*/
/*    url('Montserrat-Medium.otf') format('opentype'),*/
/*    url('Montserrat-Medium.ttf') format('truetype');*/
/*  font-weight: normal;*/
/*  font-style: normal;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Montserrat';*/
/*  src:*/
/*    url('Montserrat-SemiBold.otf') format('opentype'),*/
/*    url('Montserrat-SemiBold.ttf') format('truetype');*/
/*  font-weight: normal;*/
/*  font-style: normal;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Montserrat';*/
/*  src:*/
/*    url('Montserrat-Thin.otf') format('opentype'),*/
/*    url('Montserrat-Thin.ttf') format('truetype');*/
/*  font-weight: normal;*/
/*  font-style: normal;*/
/*}*/
